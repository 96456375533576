import React, { useEffect, useContext } from 'react';
import { graphql } from 'gatsby';

import Layout from 'components/layout';
import SEO from 'components/seo';
import { FeaturedMarketing } from 'components/CmsComponents/FeaturedMarketing';
import Components from 'components/CmsComponents/';

import { HeaderContext } from 'context/header';

const RecipesThemes = ({
  data: {
    gcms: { receitasTemas },
  },
  pageContext,
}) => {
  const {
    setMainMarginTopLaptop,
    setMainMarginTopMobile,
    showAlertPostFeriado,
    isVisibleNovoSite,
  } = useContext(HeaderContext);
  const page = receitasTemas?.[0];
  const featuredMarketingContent = page?.featuredMarketing;
  const components = page?.components ?? [];

  const dataSeo = [
    {
      seo: {
        metaTitle: page?.seo?.metaTitle ?? '',
        metaDescription: page?.seo?.metaDescription ?? '',
      },
    },
  ];

  useEffect(() => {
    if (showAlertPostFeriado || isVisibleNovoSite) {
      setMainMarginTopLaptop('9.075rem');
      setMainMarginTopMobile('7.875rem');
    } else {
      setMainMarginTopLaptop('7.063rem');
      setMainMarginTopMobile('5.875rem');
    }
  }, [showAlertPostFeriado, isVisibleNovoSite]);

  return (
    <Layout>
      <SEO
        dataSeo={dataSeo}
        image={page?.featuredMarketing?.images?.[0]?.url ?? ''}
      />
      {featuredMarketingContent && (
        <FeaturedMarketing content={featuredMarketingContent} />
      )}
      {components.length >= 1 &&
        components?.map(component => {
          return Components(component);
        })}
    </Layout>
  );
};

export const RecipeThemePageQuery = graphql`
  query recipeThemePageQuery($id: ID) {
    gcms {
      receitasTemas(locales: [pt_BR, en], where: { id: $id }) {
        id
        title
        slug
        seo {
          id
          metaTitle
          metaDescription
          noIndex
        }
        featuredMarketing {
          id
          type
          breadcrumb
          backgroundColor {
            hex
          }
          images {
            handle
            width
            height
            url
          }
          title
          fragments {
            id
            name
            type
            markdown
          }
        }
        components {
          __typename
          ... on GraphCMS_SimpleContent {
            id
            name
            type
            title
            fragments {
              id
              name
              type
              markdown
              datafragment
            }
          }
          ... on GraphCMS_HighlightContent {
            id
            name
            type
            backgroundColorInitial {
              hex
            }
            backgroundColorFinal {
              hex
            }
            title
            customTitle
            description
            images {
              handle
              width
              height
              url
            }
            fragments {
              id
              name
              type
              markdown
            }
          }
          ... on GraphCMS_HighlightFormLeadContent {
            id
            name
            type
            backgroundColorInitial {
              hex
            }
            backgroundColorFinal {
              hex
            }
            title
            customTitle
            description
            referenceToSalesforce
            images {
              handle
              width
              height
              url
            }
            documentsForDownload {
              handle
              width
              height
              url
            }
            footnote
          }
          ... on GraphCMS_VideoGalleryContent {
            id
            name
            type
            title
            headerLinkTitle
            headerLink
            description
            fragments {
              id
              name
              type
              markdown
            }
          }
          ... on GraphCMS_BannerSpecialtyContent {
            id
            name
            type
            backgroundColor {
              hex
            }
            title
            description
            images {
              handle
              width
              height
              url
            }
            fragments {
              id
              name
              type
              markdown
            }
          }
          ... on GraphCMS_ListPostContent {
            id
            name
            type
            title
            headerLinkTitle
            headerLink
            posts {
              id
              slugPost
              title
              topic
              author
              doctors(first: 1000, locales: [pt_BR, en]) {
                id
                id_api
                active
                assignment
                name
              }
              assetpicker {
                handle
                height
                width
                url
              }
              categories {
                tag
              }
            }
          }
          ... on GraphCMS_DoctorAndSpecialtyContent {
            id
            name
            type
            title
            subtitle
            estado
            images {
              handle
              width
              height
              url
            }
            specialties {
              id
              name
              slug
              customUrl
              unity
            }
            fragments {
              id
              name
              type
              markdown
            }
          }
          ... on GraphCMS_CarouselGalleryContent {
            id
            name
            type
            title
            backgroundColor {
              hex
            }
            images {
              handle
              width
              height
              url
            }
          }
          ... on GraphCMS_RecipesContent {
            id
            name
            type
            title
            receitasTemas {
              id
              title
              slug
              receitas {
                id
                receitasTipos {
                  id
                  title
                  slug
                  image {
                    id
                    height
                    width
                    url
                  }
                  receitas {
                    id
                    title
                    slug
                    receitasTemas {
                      title
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default RecipesThemes;
